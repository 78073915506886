import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Angular:`}</p>
    <pre><code parentName="pre" {...{}}>{`cloc src --exclude-dir=node_modules,bower_components,.idea,.vscode,documentation --not-match-f=package --by-file-by-lang

compodoc -p tsconfig.app.json
compodoc -s
`}</code></pre>
    <p>{`​`}</p>
    <p>{`Copodoc Workaround Angular 8: in`}<inlineCode parentName="p">{`tsconfig.app.json`}</inlineCode>{` -> remove the `}<inlineCode parentName="p">{`files`}</inlineCode>{` entry
and move content to `}<inlineCode parentName="p">{`include`}</inlineCode></p>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://github.com/compodoc/compodoc/issues/831"
      }}>{`https://github.com/compodoc/compodoc/issues/831`}</a>{`)`}</p>
    <p>{`Understand module structure / look for lazy loading.`}</p>
    <p>{`Use source-map-explorer to check the effective JavaScript bundles in the
production build.`}</p>
    <p>{`Look for usages of async/await`}</p>
    <p>{`Look for usages of Subjects / BehaviorSubjects`}</p>
    <p>{`Lookf for the usage of the `}<inlineCode parentName="p">{`asnyc`}</inlineCode>{` pipe: `}<inlineCode parentName="p">{`| async`}</inlineCode></p>
    <p>{`Look for the usage of NgZone`}</p>
    <p>{`Look for ChangeDetectionStrategy.OnPush`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      